import axios from '@/api/axios'
import useAuth from './use-auth'
import { ENDPOINTS, ENDPOINTS_AUTH, HttpStatus } from '@/config'
import type { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'
import { PUBLIC_ROUTE } from '@/router/paths'
import { toast } from 'sonner'
import { ROUTES_AUTH } from '../router/auth-router'
import { joinPath } from '@/utils'

const useRefreshToken = () => {
  const navigate = useNavigate()
  const { setAuth } = useAuth()

  const refresh = async () => {
    try {
      const response = await axios.get(
        joinPath(ENDPOINTS.AUTH, ENDPOINTS_AUTH.REFRESH),
        {
          withCredentials: true
        }
      )

      const dataUser = response.data

      setAuth(prev => {
        return {
          ...prev,
          ...dataUser
        }
      })

      return dataUser.accessToken
    } catch (e) {
      const error = e as AxiosError
      console.error(error)
      if (error?.response?.status === HttpStatus.UNAUTHORIZED) {
        navigate(`/${joinPath(PUBLIC_ROUTE.AUTH, ROUTES_AUTH.LOGIN)}`)
        setAuth(null)
        toast.error('Tu sesión ha expirado, por favor inicia sesión nuevamente')
      }
      return null
    }
  }

  return refresh
}

export default useRefreshToken
